<template>
  <div class="modal fade" ref="cmpMdlSeleccionCliente" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Selección del cliente
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <filtrador
            v-on:dato-actualizado="
              dato = $event,
              refrescarClientes()
            "
            :datoini="dato"
            placeholder="Dato del cliente"
          ></filtrador>
          <br>
          
          <div class="table-responsive">
            <table id="tblClientes" class="table table-hover table-striped" style="min-height: 350px;">
              <thead>
                <tr>
                  <th style="width: 70px">#ID</th>
                  <th>Nombre</th>
                  <th style="width: 70px">Selec</th>
                </tr>
              </thead>
              <tbody id="clientes">
                <tr v-for="cliente in clientes" :key="cliente.id">
                  <td class="fw-semibold">{{ cliente.id }}</td>
                  <td class="fw-semibold">{{ nombreCompleto(cliente) }}</td>
                  <td>
                    <button
                      class="btn btn-outline-success btn-sm"
                      @click="seleccionarCliente(cliente)"
                    >
                      <i class="mdi mdi-check-outline"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import Filtrador from '@/components/Filtrador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
export default {
  name: 'CmpMdlSeleccionCliente',
  components: { Filtrador },
  data() {
    return {
      clientes: [],
      dato: ''
    }
  },
  created: function() {
    // Control de eventos
    iu.bus.on('mostrar-mdl-seleccion-cliente', this.mostrarMdlSeleccionCliente)
  },
  methods: {
    mostrarMdlSeleccionCliente: function() {
      var modal = new Modal(this.$refs.cmpMdlSeleccionCliente)
      modal.show()
    },

    nombreCompleto: function(cliente) {
      var nm = ''
      nm += cliente.nombre

      if (cliente.apellido_paterno != null) nm += ' ' + cliente.apellido_paterno

      if (cliente.apellido_materno != null) nm += ' ' + cliente.apellido_materno

      return nm
    },

    refrescarClientes: function() {
      var self = this

      iu.spinner.mostrar('#tblClientes')

      var params = {
        dato: self.dato,
        campos: ['id', 'nombre', 'apellido_paterno', 'apellido_materno']
      }
      
      ClienteSrv.filtradosJSON(params).then(response=>{
        let res = response.data
        self.clientes = res.clientes
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tblClientes')
      })
    },

    seleccionarCliente: function(cliente) {
      var self =this
      // Carga de telefonos del cliente
      cliente.telefonos = []

      ClienteSrv.telefonosCliente(cliente.id).then(response=>{
        cliente.telefonos = response.data
        iu.bus.emit('cliente-seleccionado', cliente)
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      
        iu.bus.emit('cliente-seleccionado', cliente)
      })

      var modal = Modal.getInstance(self.$refs.cmpMdlSeleccionCliente)
      modal.hide()
    }
  },
}
</script>