<template>
  <div class="modal fade" ref="mdlSeleccionCpe" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Selección del Cpe
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <filtrador
            v-on:dato-actualizado="
              dato = $event,
              refrescarCpes()
            "
            :datoini="dato"
            placeholder="Dato del CPE"
          ></filtrador>
          <br>

          <div class="table-responsive">
            <table id="tblCpes" class="table table-hover table-striped" style="min-height: 350px;">
              <thead>
                <tr>
                  <th style="width: 70px">#ID</th>
                  <th>Nombre</th>
                  <th style="width: 70px">Selec</th>
                </tr>
              </thead>
              <tbody id="cpes">
                <tr v-for="cpe in cpes" :key="cpe.id">
                  <td>{{ cpe.id }}</td>
                  <td>{{ cpe.nombre }}</td>
                  <td>
                    <button
                      class="btn btn-outline-success btn-sm"
                      @click="seleccionarCpe(cpe)"
                    >
                      <i class="mdi mdi-check-outline"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CpeSrv from '@/services/CpeSrv.js'
import Filtrador from '@/components/Filtrador.vue'
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'CmpMdlSeleccionCpe',
  components: { Filtrador },
  data() {
    return {
      cpes: [],
      dato: ''
    }
  },
  methods: {
    mostrar(idCliente) {
      var self = this

      self.refrescarCpes(idCliente)

      var modal = new Modal(this.$refs.mdlSeleccionCpe)
      modal.show()
    },
    refrescarCpes: function(idCliente) {
      var self = this

      iu.spinner.mostrar('#tblCpes')

      var params = {
        dato: self.dato,
        limite: true,
        con_router: true
      }

      if(idCliente != null){
        Object.assign(params,{
          id_cliente: idCliente
        })
      }
      
      CpeSrv.cpesJSON(params).then(response=>{
        let res = response.data
        self.cpes = res
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tblCpes')
      })
    },
    seleccionarCpe: function(cpe) {
      var self = this
      // Carga de telefonos del cliente
      self.$emit('cpe-seleccionado', cpe)
      var modal = Modal.getInstance(this.$refs.mdlSeleccionCpe)
      modal.hide()
    }
  },
}
</script>

<style scoped>

</style>