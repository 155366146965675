<template>
  <Layout :tituloPagina="`Tickets de soporte | ${ modo == 'nuevo' ? 'Nuevo' : (ticket.mt_cierre == null ? 'Edición' : 'Visualización') }`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Edición de ticket de soporte {{ ticket.id }}
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  v-if="ticket.mt_cierre == null && !bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-if="ticket.cancelado == 1">
          <div class="col-md-12 text-center text-danger">
            <div 
              style="display: table; position: absolute; left: 0px; top: 0px; z-index: 99; font-size: 60px; width: 100%; height: 100%; ">
              <div style="margin: auto; width: 85%; margin-top: 20%; -ms-transform: rotate(-30deg); -webkit-transform: rotate(-30deg); transform: rotate(-30deg); ">
                S O P O R T E &nbsp;&nbsp; C A N C E L A D O
              </div>
            </div>
            <div style="display: table; position: absolute; left: 0px; top: 600px; z-index: 99; font-size: 60px; width: 100%; height: 100%; ">
              <div style="margin: auto; width: 85%; margin-top: 20%; -ms-transform: rotate(-30deg); -webkit-transform: rotate(-30deg); transform: rotate(-30deg); ">
                S O P O R T E &nbsp;&nbsp; C A N C E L A D O
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2" v-if="modo == 'edicion'">
            <label># Ticket</label>
            <input
              type="text"
              class="form-control"
              v-model="ticket.id"
              placeholder="# ticket"
              readonly
              disabled
            />
          </div>
          <div class="col-md-4">
            <label>Nombre</label>
            <div class="input-group" v-if="modo== 'edicion'">
              <input
                class="form-control"
                placeholder="Nombre del cliente del ticket de soporte"
                name="nombre"
                ref="nombre"
                v-model="ticket.nombre"
                :readonly="modo == 'edicion'"
                :disabled="modo == 'edicion'"
              />
              <router-link
                class="btn btn-success"
                :to="{
                  name: 'tableroCliente',
                  params: { id_cliente: ticket.id_cliente }
                }"
                v-if="modo == 'edicion' && ticket.id_cliente != null"
              >
                <i class="mdi mdi-eye"></i>
              </router-link>
            </div>
            <div class="input-group" v-if="modo == 'nuevo'">
              <input
                class="form-control"
                name="nombre"
                placeholder="Nombre del cliente del ticket de soporte"
                v-model="ticket.nombre"
                :readonly="modo == 'edicion'"
              />
              <button
                type="button"
                class="btn btn-success"
                @click="mostrarMdlSeleccionCliente()"
                v-if="modo == 'nuevo'"
              >
                <i class="bx bx-filter-alt"></i>
              </button>
            </div>
          </div>
          <div class="col-md-3">
            <label>Cpe</label>
            <div class="input-group" v-if="modo== 'edicion'">
              <input
                class="form-control"
                name="nombre_cpe"
                placeholder="Nombre del cpe del cliente"
                v-model="ticket.cpe.nombre"
                :readonly="modo == 'edicion'"
                disabled
              />
              <router-link
                class="btn btn-success"
                :to="{
                  name: 'edicionCpe',
                  params: { id: ticket.id_cpe }
                }"
                v-if="modo == 'edicion' && ticket.id_cpe != null"
              >
                <i class="mdi mdi-eye"></i>
              </router-link>
            </div>
            <div class="input-group" v-if="modo == 'nuevo'">
              <input
                class="form-control"
                v-model="ticket.cpe.nombre"
                placeholder="Nombre del cpe del cliente"
                :readonly="modo == 'edicion'"
                @click="mostrarMdlSeleccionCpe()"
              />
              <button
                type="button"
                class="btn btn-success"
                @click="mostrarMdlSeleccionCpe()"
                v-if="modo == 'nuevo'"
              >
                <i class="bx bx-filter-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2">
            <label>Prioridad</label>
            <select
              name="prioridad"
              class="form-select"
              v-model="ticket.prioridad"
              :disabled="ticket.mt_cierre != null || ticket.cancelado"
            >
              <option value="3" style="color: green" selected>Normal</option>
              <option value="2" style="color: orange">Media</option>
              <option value="1" style="color: red; font-weight: bold">URGENTE</option>
            </select>
          </div>
          <div class="col-md-2">
            <label>Fecha programada</label>
            <input
              ref="fechaProgramada"
              type="datetime-local"
              class="form-control"
              placeholder="YYYY-MM-DD HH:MM:SS"
              v-model="ticket.fecha_programada"
              :disabled="ticket.mt_cierre != null || ticket.cancelado"
            />
          </div>
          <div class="col-md-5">
            <label>Tiempo estimado</label>
            <div class="row text-center">
              <div class="col-sm-4 col-4">
                <input
                  name="tiempoDias" type="number"
                  class="form-control text-center" ref="tiempoDias"
                  v-model="ticket.tiempo_estimado_dias"
                  min="0" max="365"
                  placeholder="0"
                  :disabled="ticket.mt_cierre != null || ticket.cancelado"
                />
                <label>Dias</label>
              </div>
              <div class="col-sm-4 col-4">
                <input
                  name="tiempoHoras" type="number"
                  class="form-control text-center" ref="tiempoHoras"
                  v-model="ticket.tiempo_estimado_horas"
                  min="0" max="23"
                  placeholder="0"
                  :disabled="ticket.mt_cierre != null || ticket.cancelado"
                />
                <label>Horas</label>
              </div>
              <div class="col-sm-4 col-4">
                <input
                  name="tiempoMinutos" type="number"
                  class="form-control text-center" ref="tiempoMinutos"
                  v-model="ticket.tiempo_estimado_minutos"
                  min="0" max="59"
                  placeholder="0"
                  :disabled="ticket.mt_cierre != null || ticket.cancelado"
                />
                <label>Minutos</label>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2">
            <label>Numero del cliente</label>
            <div class="input-group">
              <span class="input-group-text">
                <eva-icon name="hash-outline"></eva-icon>
              </span>
              <input
                class="form-control"
                name="numero_cliente"
                placeholder="X00000"
                v-model="ticket.numero_cliente"
                :readonly="modo == 'edicion'"
                :disabled="modo == 'edicion'"
              />
            </div>
          </div>
          <div class="col-md-4">
            <label>Correo</label>
            <div class="input-group">
              <span class="input-group-text">
                <eva-icon name="at-outline"></eva-icon>
              </span>
              <input
                class="form-control"
                name="correo"
                placeholder="correo@ejemplo.com"
                v-model="ticket.correo"
                :readonly="modo == 'edicion'"
                :disabled="modo == 'edicion'"
              />
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-2">
            <label>Dirección IP</label>
            <div class="input-group">
              <input
                class="form-control"
                name="direccion_ip"
                placeholder="172.XXX.XXX.XXX"
                v-model="ticket.cpe.ip_wan"
                :readonly="modo == 'edicion'"
                :disabled="modo == 'edicion'"
              />
              <a :href="`http://${ticket.cpe ? ticket.cpe.ip_wan : 'sin-ip'}`" target="_blank" class="btn btn-soft-dark" title="WEB UI">
                <i class="bx bx-link-external"></i>
              </a>
            </div>
          </div>
          <div class="col-md-2" v-if="ticket.cpe && ticket.cpe.contrato">
            <label>Servicio</label>
            <div class="input-group">
              <span
                class="input-group-text"
                v-if="ticket.cpe.contrato && ticket.cpe.contrato.id_tipo_renta == 1"
                title="Renta Mensual Fija"
              >
                RMF
              </span>
              <span
                class="input-group-text"
                v-if="ticket.cpe.contrato && ticket.cpe.contrato.id_tipo_renta == 2"
                title="Renta Mensual Variable"
              >
                RMV
              </span>
              <input
                class="form-control"
                name="nombre_servicio"
                placeholder="Nombre del servicio"
                v-model="ticket.cpe.contrato.servicio.nombre"
                :readonly="modo == 'edicion'"
                :disabled="modo == 'edicion'"
              />
            </div>
          </div>
          <div class="col-md-3" v-if="ticket.cpe && ticket.cpe.router">
            <label>Router</label>
            <input
              class="form-control"
              placeholder="Nombre del router"
              name="router"
              v-if="ticket.cpe && ticket.cpe.router"
              v-model="ticket.cpe.router.nombre"
              :readonly="modo == 'edicion'"
              :disabled="modo == 'edicion'"
            />
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-lg-6 col-md-6">
            <label>Teléfono(s)</label>
            <div class="row">
              <div class="col-md-12">
                <div class="input-group">
                  <select
                    style="width: 100px"
                    class="form-select"
                    v-model="tipoTelefono"
                    :disabled="ticket.mt_cierre != null || ticket.cancelado"
                  >
                    <option value="celular">Celular</option>
                    <option value="casa">Casa</option>
                    <option value="trabajo">Trabajo</option>
                    <option value="otro">Otro</option>
                  </select>
                  <input type="text" v-model="prefijo" title="Prefijo telefónico"
                    class="form-control" placeholder="+52"
                    style="width: 80px"
                    :disabled="ticket.mt_cierre != null || ticket.cancelado"
                  />
                  <input
                    name="numeroTelefonico"
                    ref="numeroTelefonico"
                    type="text"
                    style="min-width: 130px"
                    class="form-control"
                    v-model="numeroTelefono"
                    :disabled="ticket.mt_cierre != null || ticket.cancelado"
                    v-on:keyup.enter="agregarTelefono()"
                    onkeypress="return check(event, 'telefono')"
                    placeholder="55 1234 5678"
                  />
                  <button
                    class="btn btn-success"
                    @click="agregarTelefono()"
                    type="button"
                    :disabled="ticket.mt_cierre != null || ticket.cancelado"
                    v-if="ticket.mt_cierre == null && !ticket.cancelado"
                  >
                    <i class="mdi mdi-plus"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive" style="height: 200px">
              <table id="tbl-telefonos" class="table table-hover">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Prefijo</th>
                    <th>Número</th>
                    <th style="width:50px" v-show="ticket.mt_cierre == null">
                      <i class="fa fa-minus"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(telefono, index) in ticket.telefonos"
                    :key="telefono.id"
                  >
                    <td>{{telefono.tipo}}</td>
                    <td>
                      <input
                        type="text"
                        v-model="telefono.prefijo"
                        class="prefijo"
                        :class="modoTemplate == 'dark' ? 'text-gris' : 'text-dark'"
                      />
                    </td>
                    <td>
                      <input 
                        type="text"
                        v-model="telefono.numero" class="telefono"
                        onkeypress="return check(event, 'telefono')"
                        :class="modoTemplate == 'dark' ? 'text-gris' : 'text-dark'"
                      />
                    </td>
                    <td>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="eliminarTelefono(index)"
                        v-show="ticket.mt_cierre == null && !ticket.cancelado"
                      >
                        <i class="mdi mdi-minus"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-6 col-md-6" v-show="ticket.mt_inicio">
            <label>Tiempo de sesión</label>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group">
                  <div class="input-group-text">Inicio</div>
                  <input
                    type="text"
                    class="form-control text-center"
                    :value="ticket.mt_inicio"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <div class="input-group-text">Fin</div>
                  <input
                    type="text"
                    class="form-control text-center"
                    :value="ticket.mt_cierre"
                    readonly
                  />
                </div>
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <router-link
                  :to="{
                    name: 'sesionesTicketSoporte',
                    params: { id: ticket.id }
                  }"
                  v-if="ticket.id != null"
                  class="btn btn-light"
                >
                  <i class="mdi mdi-account-clock-outline"></i>
                  Sesiones de soporte
                </router-link>

              </div>
              <div class="col-md-6 text-right">
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="mdi mdi-clock-outline"></i>
                    &nbsp;
                    Tiempo soporte
                  </span>
                  <input 
                    type="text"
                    class="form-control text-center"
                    :value="tiempo_soporte"
                    readonly
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <h5>Notas</h5>
        <hr style="margin-top: 1rem; margin-bottom: 1rem;">

        <div class="row">
          <div class="col-md-6">
            <label>Nota del cliente</label>
            <textarea
              class="form-control"
              name="nota_cliente"
              placeholder="Nota del cliente"
              v-model="ticket.nota_cliente"
              :disabled="ticket.mt_cierre != null || ticket.cancelado"
            ></textarea>
          </div>
          <div class="col-md-6">
            <label>Nota de soporte</label>
            <textarea
              placeholder="Nota del soporte"
              class="form-control"
              name="nota_soporte"
              v-model="ticket.nota_soporte"
              :readonly="ticket.mt_cierre != null || ticket.cancelado"
            ></textarea>
          </div>
        </div>

        <br>
        <h5>Mapa</h5>
        <hr style="margin-top: 1rem; margin-bottom: 1rem;">

        <div class="row">
          <div class="col-md-12">
            <GoogleMap
              ref="mapa"
              :api-key="apikeyGoogle"
              :center="posicionInicial"
              style="width: 100%; height:500px"
              :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
              :zoom="mapaZoom"
            >
              <Marker
                ref="marker"
                :options="{ 
                  position: posicionInicial,
                  title: 'Empresa',
                  icon: markerIcon,
                  draggable: ticket.mt_cierre == null && !ticket.cancelado ? true : false
                }"
                @dragend="mostrarPosicion($event)"
              ></Marker>
            </GoogleMap>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <a :href="`https://maps.google.com/?q=${ticket.latitud},${ticket.longitud}&entry=gps`" target="_blank">
              Ver en google maps
            </a>
          </div>
          <div class="offset-md-6 col-md-6">
            <div class="input-group">
              <div class="input-group-text">Latitud</div>
              <input
                type="text"
                class="form-control text-right"
                v-model="ticket.latitud"
                readonly
              />
              <div class="input-group-text">Longitud</div>
              <input
                type="text"
                class="form-control text-right"
                v-model="ticket.longitud"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right pb-4">
      <button
        class="btn btn-danger btn-outline"
        @click="preguntaCancelar()"
        v-if="modo=='edicion' && ticket.mt_cierre == null && !ticket.cancelado"
      >
        <span class="d-none d-sm-block">
          <i class="mdi mdi-cancel"></i>
          Cancelar
        </span>
        <span class="d-block d-sm-none">
          <i class="mdi mdi-cancel"></i>
        </span>
      </button>
      <div class="btn-group ms-1">
        <a
          href="#"
          onclick="return false"
          @click="atras()"
          class="btn btn-secondary"
        >
        <i class="mdi mdi-chevron-left"></i>
          Atras
        </a>
        <button
          class="btn btn-primary"
          v-if="ticket.mt_inicio == null && !ticket.completado && modo == 'edicion' && !ticket.cancelado"
          @click="iniciarSoporte()"
        >
          <i class="mdi mdi-play-outline font-size-15"></i>
          Iniciar soporte
        </button>
        <button
          class="btn btn-warning"
          v-if="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado && modo == 'edicion' && !ticket.cancelado"
          @click="!ticket.bpausa ? pausarSoporte() : reanudadSoporte()"
        >
          <i 
            class="mdi font-size-15"
            :class="!ticket.bpausa ? 'mdi-pause' : 'mdi-play-outline'"
          ></i>
          {{ !ticket.bpausa ? 'Pausar' : 'Reanudad' }} soporte
        </button>
        <button
          class="btn btn-dark"
          v-if="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado && modo == 'edicion' && !ticket.cancelado"
          @click="finalizarSoporte()"
        >
          <i class="mdi mdi-stop font-size-15"></i>
          finalizar soporte
        </button>
        <button
          class="btn btn-success"
          @click="modo == 'nuevo' ? guardar() : actualizar()"
          v-if="ticket.mt_cierre == null && !ticket.cancelado"
          :disabled="bandera_spinner"
        >
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          {{ modo == 'nuevo' ? 'Guardar' : '' }}
          {{ modo == 'edicion' ? 'Actualizar' : '' }}
        </button>
      </div>
    </div>
    <cmp-mdl-seleccion-cliente />
    <cmp-mdl-seleccion-cpe 
      ref="seleccionCpe"
      v-on:cpe-seleccionado="cargarCpeSeleccionado"
    />
    <br>
  </Layout>
</template>

<script>
// Componente Ticket de soporte
// El "modo" indica cómo va a actuar este ticket
import { GoogleMap, Marker } from "vue3-google-map"
import markerIcon from '@/assets/img/location24x36.png'
import cmpMdlSeleccionCpe from './CmpMdlSeleccionCpe.vue'
import cmpMdlSeleccionCliente from './CmpMdlSeleccionCliente.vue'
import TicketSoporteSrv from '@/services/TicketSoporteSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import SesionTicketSoporteSrv from '@/services/SesionTicketSoporteSrv.js'
import moment from 'moment'
import Swal from 'sweetalert2'
import Layout from "@/views/layouts/main"
export default {
  name: 'EdicionTicketSoporte',
  components: {
    Layout,
    cmpMdlSeleccionCliente,
    cmpMdlSeleccionCpe,
    GoogleMap,
    Marker
  },
  props: ['id'],
  data() {
    return {
      modo: 'nuevo',
      ticket: {
        cpe: {
          id: null,
          nombre: '',
          contrato: {
            servicio:{}
          },
          router: {}
        },
        sesiones_soporte: [],
        id: null,
        id_cliente: null,
        numero_cliente: null,
        correo: '',
        id_cpe: null,
        nombre: '',
        telefono: '',
        nota_cliente: '',
        nota_soporte: '',
        completado: 0,
        prioridad: 3,
        latitud: 0,
        longitud: 0,
        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,
        cancelado: 0,
        telefonos: [
          // {
          //   numero: '5512341234',
          //   prefijo: 52,
          //   tipo: 'celular'
          // }
        ]
      },
      ticketInicial: {},
      tipoTelefono: 'celular',
      numeroTelefono: '',
      prefijo: null,
      paises: [],
      bandera_spinner: false,
      markerIcon:markerIcon,
      mapaZoom: 12,
      posicionInicial: {
        lat: 0,
        lng: 0
      },
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ]
    }
  },
  computed: {
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    },
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    },
    tiempo_soporte: function() {
      let self = this, segundos = 0

      self.ticket.sesiones_soporte.forEach(sesion => {
        if(sesion.fin != null ) {
          let finicial = moment(sesion.inicio)
          let ffinal = moment(sesion.fin)
          let dsegundos = ffinal.diff(finicial, 'seconds')

          segundos += dsegundos
        }
      })

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)

      return dias+' dia '+horas+' hrs '+minutos+' min'
    }
  },
  created() {
    var self = this

    this.ticketInicial = Object.assign({}, this.ticket)

    if (this.$route.path.indexOf('nuevo') == -1) this.modo = 'edicion'
    else self.ticket.fecha_programada =  moment().format('YYYY-MM-DD HH:mm:ss')

    if (self.$route.query.fecha_programada) self.ticket.fecha_programada = moment(self.$route.query.fecha_programada).format('YYYY-MM-DD HH:mm:ss')
    if (self.$route.query.tiempo_estimado) self.ticket.tiempo_estimado = self.$route.query.tiempo_estimado

    self.cargarPaises()
    self.cargarPrefijoTelefonico()

    iu.bus.on('cliente-seleccionado', this.cargarClienteSeleccionado)
  },

  mounted: function() {
    var self = this,
        id = this.$route.params.id

    // Si hay un id de ticket activo, se procede a cargar el ticket
    if (this.modo == 'edicion') {
      this.ticket.id = id
      this.cargarTicketSoporte()
    } else self.cargarPosicionInicial()
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      if(self.ticket.nombre == null || self.ticket.nombre == ''){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita un nombre')
        self.bandera_spinner = false
        return
      }

      if(self.ticket.fecha_programada == null || self.ticket.fecha_programada == ''){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita una fecha programada')
        self.$refs.fechaProgramada.focus()
        self.bandera_spinner = false
        return
      }

      self.ticket.tiempo_estimado = self.calcularDiasASegundos(self.ticket.tiempo_estimado_dias) +
                                    self.calcularHorasASegundos(self.ticket.tiempo_estimado_horas) +
                                    self.calcularMinutosASegundos(self.ticket.tiempo_estimado_minutos)

      if(self.ticket.tiempo_estimado == 0){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita un tiempo estimado')
        self.$refs.tiempoMinutos.focus()
        self.bandera_spinner = false
        return
      }

      TicketSoporteSrv.actualizar(self.ticket).then(response=>{
        iu.msg.success('Se actualizó correctamente')
        self.cargarTicketSoporte()
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    agregarTelefono: function() {
      var self = this

      if(self.numeroTelefono.length > 20){
        iu.msg.warning('El numero telefónico no puedo contener mas de 20 números.')
        self.$refs.numeroTelefonico.focus()
        return
      }
      
      if(self.numeroTelefono.length == ''){
        iu.msg.warning('Se debe de registrar un numero.')
        self.$refs.numeroTelefonico.focus()
        return
      }

      if(self.prefijo == null || self.prefijo == '') {
        iu.msg.warning('No se ha ingresado un prefijo telefónico')
       self.$refs.prefijo.focus()
       return
     }
      
      self.ticket.telefonos.push({
        numero: self.numeroTelefono,
        prefijo: self.prefijo && self.prefijo.substring(0, 1) == '+'? self.prefijo : '+' + self.prefijo,
        tipo: self.tipoTelefono
      })
      
      self.numeroTelefono = ''
      self.tipoTelefono = 'celular'
    },

    atras: function() {
      this.$router.go(-1)
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600
    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },

    cargarClienteSeleccionado: function(cliente) {
      var self = this
      var nombre = ''

      self.ticket.id_cliente = cliente.id

      nombre = cliente.nombre

      if (cliente.apellido_paterno != null)
        nombre += ' ' + cliente.apellido_paterno

      if (cliente.apellido_materno != null)
        nombre += ' ' + cliente.apellido_materno

      self.ticket.nombre = nombre
      self.ticket.numero_cliente = cliente.numero
      self.ticket.correo = cliente.correo
      self.ticket.telefonos = cliente.telefonos.map(function(telefono){
        return (({numero, prefijo, tipo})=>({numero, prefijo, tipo}))(telefono)
      })
    },

    cargarCpeSeleccionado: function(cpe){
      var self = this
      self.ticket.cpe = cpe
      self.ticket.id_cpe = cpe.id
      self.ticket.latitud = parseFloat(cpe.latitud)
      self.ticket.longitud = parseFloat(cpe.longitud)

      self.posicionInicial = {
        lat: parseFloat(cpe.latitud),
        lng: parseFloat(cpe.longitud)
      }
    },

    cargarTicketSoporte: function() {
      var self = this
      
      TicketSoporteSrv.ticketJSON(this.id).then(response=>{
        self.ticket = response.data

        if(self.ticket.cpe.router == null) self.ticket.cpe.router = { nombre: '' }

        self.ticket.tiempo_estimado_dias = self.convertidorSegundos_a_DHM(self.ticket.tiempo_estimado )[0]
        self.ticket.tiempo_estimado_horas = self.convertidorSegundos_a_DHM(self.ticket.tiempo_estimado )[1]
        self.ticket.tiempo_estimado_minutos = self.convertidorSegundos_a_DHM(self.ticket.tiempo_estimado )[2]

        self.posicionInicial = {
          lat: parseFloat(self.ticket.latitud),
          lng: parseFloat(self.ticket.longitud)
        }
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el ticket de soporte'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPaises: function() {
      var self = this
      
      let params = {
        datos_solicitados: ['id','nombre','prefijo_telefonico']
      }

      SistemaSrv.paisesJSON(params).then(response => {
        self.paises = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los paises'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPrefijoTelefonico: function() {
      var self = this

      SistemaSrv.ajustes(['prefijo_telefonico']).then(response => {
        let ajustes = response.data
        self.prefijo = ajustes.prefijo_telefonico
      })
    },

    preguntaCancelar: function() {
      var self = this,
        ticket = self.ticket

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: 'Cancelar ticket de soporte',
        html: '¿Desea cancelar el ticket de soporte del cliente <br> <strong>' + ticket.nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          TicketSoporteSrv.cancelar(ticket.id).then(response=>{
            swal.fire("Cancelado!", "Se a cancelado correctamente", "success")
            self.cargarTicketSoporte()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return [ dias, horas, minutos ]
    },

    formatoDate(mt, formato = 'DD/MM/YYYY') {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format(formato)
    },

    iniciarSoporte: function() {
      var self = this,
        idTicket = self.ticket.id

      SesionTicketSoporteSrv.iniciar(idTicket).then(response => {
        iu.msg.success('Se inició correctamente la sesión de soporte')
        self.cargarTicketSoporte()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo iniciar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    pausarSoporte: function() {
      var self = this,
          ticket = self.ticket

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Deseas pausar el ticket de soporte?",
        html: `¿Está seguro de pausar el ticket de soporte <strong>${ticket.id}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.motivoPausa(ticket)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    motivoPausa: function(ticket){
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Motivo de pausa del ticket de soporte",
        html: "Escriba el motivo de pausa del ticket de soporte:",
        icon: "question",
        input: "textarea",
        inputPlaceholder: "Escribe un motivo para pausar el soporte.",
        showCancelButton: true,
        confirmButtonText: "Pausar",
        cancelButtonText: "Cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
        allowOutsideClick: false
      }).then(text => {
        if (text.value) {
          let sesion = { motivo_pausa: text.value }

          SesionTicketSoporteSrv.pausar(ticket.id, sesion).then(response => {
            swal.fire("Pausado!", `La sesión de soporte se ha pausado correctamente, con motivo: <br><br> ${text.value}`, "success");
            self.cargarTicketSoporte()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo pausar el ticket de soporte '+ticket.id
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    reanudadSoporte: function() {
      var self = this,
          idTicket = self.ticket.id

      SesionTicketSoporteSrv.reanudar(idTicket).then(response => {
        iu.msg.success('Se a reanudado correctamente la sesión de soporte')
        self.cargarTicketSoporte()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo reanudar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    finalizarSoporte: function() {
      var self = this,
          idTicket = self.ticket.id

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Deseas finalizar el ticket de soporte?",
        html: `¿Está seguro de finalizar el ticket de soporte <strong>${idTicket}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SesionTicketSoporteSrv.finalizar(idTicket).then(response => {
            swal.fire("Finalizado!", "La sesión de soporte se ha finalizado correctamente", "success");
            self.cargarTicketSoporte()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo finalizar el ticket de soporte '+idTicket
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    guardar() {
      var self = this

      self.bandera_spinner = true

      if(self.ticket.nombre == null || self.ticket.nombre == ''){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita un nombre')
        self.bandera_spinner = false
        return
      }

      if(self.ticket.fecha_programada == null || self.ticket.fecha_programada == ''){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita una fecha programada')
        self.$refs.fechaProgramada.focus()
        self.bandera_spinner = false
        return
      }

      self.ticket.tiempo_estimado = self.calcularDiasASegundos(self.ticket.tiempo_estimado_dias) +
                                    self.calcularHorasASegundos(self.ticket.tiempo_estimado_horas) +
                                    self.calcularMinutosASegundos(self.ticket.tiempo_estimado_minutos)

      if(self.ticket.tiempo_estimado == 0){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita un tiempo estimado')
        self.$refs.tiempoMinutos.focus()
        self.bandera_spinner = false
        return
      }

      TicketSoporteSrv.guardar(self.ticket).then(response=>{
        iu.msg.success('Se guardó correctamente')
        self.limpiar()
        self.cerrar()
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el ticket de soporte'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    cargarPosicionInicial: function (){
      var self = this

      SistemaSrv.posicionInicial().then(response => {
        var posicion = response.data
        self.ticket.latitud = parseFloat(posicion.latitud)
        self.ticket.longitud = parseFloat(posicion.longitud)

        self.posicionInicial = {
          lat: parseFloat(posicion.latitud),
          lng: parseFloat(posicion.longitud)
        }
        
      }).catch(error => { self.mostrarMensajeError(error, 'No se pudo obtener la posicion inicial') })
    },

    eliminarTelefono: function(index) {
      var self = this

      self.ticket.telefonos.splice(index, 1)
    },

    limpiar() {
      Object.assign(this.ticket, this.ticketInicial)
      this.ticket.telefonos.splice(0)
    },

    mostrarPosicion: function(event) {
      var self = this

      self.ticket.latitud = event.latLng.lat()
      self.ticket.longitud = event.latLng.lng()

      self.posicionInicial = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
    },

    mostrarMensajeError(error, mensaje = '') {
      let message
      try {
        message = error.response.data.message
      } catch (e) {
        message = mensaje
      }
      mensaje != '' ? iu.msg.error(mensaje) : null
      console.log(error)
    },

    mostrarMdlSeleccionCliente: function() {
      // Lanza el evento para mostrar el cmpMdlSeleccionCliente
      iu.bus.emit('mostrar-mdl-seleccion-cliente')
    },

    mostrarMdlSeleccionCpe: function() {
      var self = this
      this.$refs.seleccionCpe.mostrar(self.ticket.id_cliente)
    }
  }
}
</script>

<style scoped>
#tblClientes {
  height: 400px;
}
textarea[name='nota_cliente'] {
  height: 200px;
}
textarea[name='nota_soporte'] {
  height: 200px;
}
.prefijo {
  width: 100%;
  border: none;
  background-color: transparent;
}
.telefono {
  width: 100%;
  border: none;
  background-color: transparent;
}
.text-gris {
  color: #8C9FB9;
}
</style>